// ================================================================================================
//  File Name: include.scss
//  Description: Common components file to include all theme specific custom components.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Variables
// ------------------------------

// Functions
@import 'bootstrap/scss/functions'; // Bootstrap core function
@import 'functions'; // Bootstrap extended function

// Variables
@import 'scss/variables/variables'; // Bootstrap custom variable override (for user purpose)
@import 'variables'; // Bootstrap extended variable override
@import 'bootstrap/scss/variables'; // Bootstrap core variable


// Define custom colors
$custom-colors: (
  "secondary": #607d8b, // Override default secondary
  "wa-primary-2": #344d74, // Override default secondary
  "wa-info": #01b0f1,   // Custom info color
  "wa-warning": #f9c347,   // Custom info color
  "wa-success": #13b059,
  "wa-white": #ffffff, // Custom success color
  "wa-red": #dc3545 // Custom success color
);

// Merge with the default theme colors
$theme-colors: map-merge($theme-colors, $custom-colors);

// Generate button styles for each color in $custom-colors
@each $color-name, $color-value in $custom-colors {
  .btn-#{$color-name} {
    background-color: $color-value;
    color: #ffffff; // Default to white text
    border: none; // Default border

    // Special case for btn-white
    @if $color-name == 'wa-white' {
      color: #000000; // Black text for white button
      border: 1px solid #00000014; // Black border for white button
    }

    &:hover {
      background-color: darken($color-value, 10%); // Darken on hover
      color: #ffffff;
      box-shadow: 0 4px 10px rgba($color-value, 0.5); // Add a shadow effect

      // Special case for btn-white
      @if $color-name == 'wa-white' {
        color: #000000; // Ensure black text remains on hover
        background-color: darken($color-value, 5%); // Slightly darken white
        border: 1px solid #a1a1a1; // Ensure border remains black
      }
    }
  }
}

@each $color-name, $color-value in $custom-colors {
    .bg-#{$color-name} {
      background-color: $color-value;
      color: #ffffff; // Ensure white text
    }
  }

// Mixins
@import 'bootstrap/scss/mixins'; // Bootstrap core mixins
@import 'mixins'; // Bootstrap extended mixins
